
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import { PlantMesses, PlantInfo, PlantDicDetail } from '@/types/plantEstimation'
import { ElForm } from 'element-ui/types/form'
import { twoDecimalNoZero } from '@/utils/validate'

@Component({
  components: { UploadFile }
})
export default class PlantEstimationAdd extends Vue {
  private projectList = []
  private plantList: Array<{ plantName: string; list: PlantMesses[] }> = []
  private formInfo: PlantInfo = {
    projectId: '',
    engineeringName: '',
    engineeringNum: '',
    engineeringArea: '',
    resourceList: [],
    pictureList: []
  }

  private rulesForm = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    engineeringName: [
      { required: true, message: '请输入工程名称', trigger: 'blur' }
    ],
    engineeringNum: [
      { required: true, message: '请输入工程编号', trigger: 'blur' }
    ],
    engineeringArea: [
      { required: true, message: '请输入工程面积', trigger: 'blur' },
      { validator: twoDecimalNoZero, trigger: ['change', 'blur'] }
    ]
  }

  private loading = false
  private tableForm: { list: Array<PlantMesses> } = {
    list: []
  }

  private tableRules = {
    plantName: [
      { required: true, message: '请选择植物名称', trigger: 'change' }
    ],
    plantType: [
      { required: true, message: '请选择植物型号', trigger: 'change' }
    ],
    plantArea: [
      { required: true, message: '请输入种植面积', trigger: 'blur' },
      { validator: twoDecimalNoZero, trigger: ['change', 'blur'] }
    ]
  }

  get estimatedId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getProjectList()
    this.getPlant()
    if (this.estimatedId) {
      this.getDetail()
    }
  }

  getProjectList () {
    this.$axios.get(this.$apis.project.selectProjectByList).then((res) => {
      this.projectList = res.list || []
    })
  }

  // 植物字典
  getPlant () {
    this.$axios.get(this.$apis.plantEstimated.selectPlantDictionaryGroup).then((res) => {
      this.plantList = res || []
    })
  }

  getDetail () {
    this.loading = true
    this.$axios.get<{plantEstimated: PlantInfo; plantMessList: Array<PlantMesses>}>(this.$apis.plantEstimated.selectPlantEstimatedByEstimatedId,
      {
        estimatedId: this.estimatedId
      }).then((res) => {
      this.formInfo = res.plantEstimated || {}
      this.tableForm.list = this.formatPlantMessList(res.plantMessList || [])
    }).finally(() => {
      this.loading = false
    })
  }

  // 种植信息处理
  formatPlantMessList (list: Array<PlantMesses>) {
    let nameIndex: number | null = null
    let typeIndex: number | null = null
    return list.map((item) => {
      nameIndex = this.plantList.findIndex(nItem => nItem.plantName === item.plantName)
      typeIndex = nameIndex >= 0 ? this.plantList[nameIndex].list.findIndex(nItem => nItem.plantType === item.plantType) : -1
      return {
        ...item,
        plantNameIndex: nameIndex >= 0 ? nameIndex : null,
        plantTypeIndex: typeIndex >= 0 ? typeIndex : null
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formInfo.resourceList = [file]
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).clearValidate()
    })
  }

  imgRemove (index: number) {
    this.formInfo.resourceList.splice(index, 1)
  }

  // 文件上传成功返回
  onSuccess1 (file: FileInfo) {
    this.formInfo.pictureList.push(file)
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).clearValidate()
    })
  }

  imgRemove1 (index: number) {
    this.formInfo.pictureList.splice(index, 1)
  }

  blurArea (row: any, index: number) {
    // 非负浮点数
    const regPos = /^\d+(\.\d+)?$/
    if (regPos.test(row.plantArea)) {
      row.estimatedCount = (Math.ceil(Number(row.plantArea) / Number(row.areaCovered))).toString()
      // 数据改变
      this.$set(this.tableForm.list, index, row)
    }
  }

  // 种植信息开始
  // 新增
  addPlant () {
    this.$nextTick(() => {
      this.tableForm.list.push({
        dicId: '',
        areaCovered: '',
        plantArea: '',
        estimatedCount: '',
        plantNameIndex: null,
        plantTypeIndex: null
      })
    })
  }

  // 名称选择
  selectplantName (row: PlantMesses) {
    // 清空已填好的数据
    row.areaCovered = ''
    row.plantArea = ''
    row.estimatedCount = ''
    row.plantTypeIndex = null
  }

  // 型号选择
  selectplantType (row: PlantMesses) {
    // 清空已填好的数据
    row.plantArea = ''
    row.estimatedCount = ''
    // 传id
    const plant = this.plantList[row.plantNameIndex as number].list[row.plantTypeIndex as number]
    row.dicId = plant.dicId || ''
    row.areaCovered = plant.areaCovered || ''
  }

  onDelete (index: number) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.tableForm.list.splice(index, 1)
        this.$message.success('删除成功')
      })
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate((valid) => {
      if (valid) {
        // 判断是否新增种植信息列表
        if (this.tableForm.list.length > 0) {
          (this.$refs.addTable as ElForm).validate((valid) => {
            if (valid) {
              const plantMesses: Array<{ dicId: string; messId: string; plantArea: string; estimatedCount: string }> = []
              this.tableForm.list.forEach((item) => {
                plantMesses.push({
                  dicId: item.dicId || '',
                  messId: item.messId || '',
                  plantArea: item.plantArea,
                  estimatedCount: item.estimatedCount as string
                })
              })
              const url = this.estimatedId ? this.$apis.plantEstimated.updatePlantEstimated : this.$apis.plantEstimated.insertPlantEstimated
              this.$axios.post(url, {
                ...this.formInfo,
                plantMesses: plantMesses
              }).then(() => {
                this.$message.success('保存成功')
                this.$router.push({ name: 'plantEstimation' })
              })
            }
          })
        } else {
          this.$message.warning('请添加种植信息')
        }
      }
    })
  }

  onCancel () {
    this.$router.push({ name: 'plantEstimation' })
  }

  getSummaries (param: {columns: any; data: Array<PlantDicDetail>}) {
    const { columns, data } = param
    const sums: any = []
    columns.forEach((column: any, index: number) => {
      if (index === 0) {
        sums[index] = '合计'
      } else if (index === 4) {
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        }
      }
    })
    return sums
  }
}
